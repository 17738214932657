<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{on}">
      <v-text-field
        outlined
        v-model="value"
        :label="label"
        :placeholder="placeholder"
        append-icon="event"
        readonly
        dense
        :rules="rules"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      reactive
      v-model="date"
      no-title
      scrollable
      :allowed-dates="allowedDates"
      @input="update"
      @change="$refs.menu.save(date)"
    >
      <!-- <v-spacer></v-spacer> -->
      <!-- <v-btn text color="primary" @click="menu = false">Cancel</v-btn> -->
      <!-- <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn> -->
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: ['value', 'label', 'allowedDates', 'rule', 'placeholder'],
  methods: {
    update() {
      this.$emit('input', this.date)
    }
  },
  data() {
    return {
      menu: false,
      date: this.value,
      rules: this.rule || [v => !!v || 'Date is required']
    }
  }
}
</script>
