<template>
  <v-dialog v-model="dialog" persistent min-width="800px" max-width="1200px" max-height="80%">
    <template v-slot:activator="{on}">
      <v-btn large v-on="on">{{ activatorText }}</v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col
              cols="12"
              sm="11">
            <span class="headline">Link Content</span>
          </v-col>
          <v-col
              cols="12"
              sm="1">
            <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>

        <v-container>
          <v-row>

            <v-col>
              <!-- Content type -->
              <v-form ref="form" class="container" lazy-validation>
                <v-select label="Select Content Type" v-model="select" :items="sections"
                          @change="changeChange"></v-select>
              </v-form>
            </v-col>
            <v-col>
              <!-- Search -->
              <v-text-field
                  v-show="this.sectionId"
                  @input="textSearch"
                  v-model="search"
                  append-icon="search"
                  label="Search by title"
                  single-line
                  hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-data-table :loading="isLoad" :headers="headers" :items="contentList" @click:row="itemClick"
                        :items-per-page="pageSize"
                        :options.sync="options"
                        :server-items-length="totalSize"
                        :footer-props="{'items-per-page-options': itemPerPages}"
          >
            <template v-slot:item.update_date="{item}">{{
              dateFmt(item.update_date)
              }}
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import {mapState, mapActions} from 'vuex'
  import {convertDate} from '../../helper/helper'

  export default {
    props: ['activatorText', 'resetContent'],
    created() {
      this.fetchSections()
    },
    computed: {
      ...mapState('notification', ['notificationObject', 'sections']),
    },
    methods: {
      closeDialog() {
        this.dialog = false
        this.contentList = []
        this.$refs.form.reset()
        this.page = 1
        this.pageSize = 25
        this.totalSize = 0
        this.sectionId = null
        this.search = ''
        this.options.page = 1
      },
      textSearch() {
        this.fetch()
      },
      dateFmt: date => convertDate(date),
      async fetch() {
        this.isLoad = true
        let payload = {
          id: this.sectionId,
          search: this.search,
          page: this.page,
          pageSize: this.pageSize,
          orgs: this.notificationObject.orgs.map(org => org.codename),
        }
        let list = await this.fetchContentList(payload)
        this.totalSize = list.total
        this.contentList = list.results
        this.isLoad = false
      },
      async changeChange(val) {
        this.sectionId = val
        this.fetch()
      },
      itemClick(item) {
        let payload = {
          section_id: this.sectionId,
          content_id: item.id,
          contents: item.content,
        }
        this.setLinkedContent(payload).then(() => (this.dialog = false))
      },
      ...mapActions('notification', ['setLinkedContent', 'fetchSections', 'fetchContentList']),
    },
    watch: {
      options: {
        handler() {
          const {page, itemsPerPage} = this.options
          this.page = page
          this.pageSize = itemsPerPage
          if (this.sectionId)
            this.fetch()
        },
        deep: true,
      },
    },
    data: () => ({
      search: '',
      options: {},
      select: 0,
      sectionId: null,
      isLoad: false,
      page: 1,
      pageSize: 25,
      itemPerPages: [25, 50, 100],
      totalSize: 0,
      dialog: false,
      headers: [
        {text: 'Title (TH)', value: 'content[0].title', sortable: false},
        {text: 'Title (EN)', value: 'content[1].title', sortable: false},
        {text: 'Updated Date', value: 'update_date', sortable: false},
      ],
      selectedKey: '',
      contentList: [],
      samples: {
        packages: [],
      },
    }),
  }
</script>






