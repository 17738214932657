<template>
  <v-container class="px-10" fluid>
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbs" divider=">" class="pa-0"></v-breadcrumbs>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h1 class="title">Push Notification</h1>
      </v-col>
    </v-row>
    <v-popup-loading :isLoading="isLoading"/>
    <v-alert-error :show="isError" :close="closeAlert"></v-alert-error>
    <v-alert-success :show="isSuccess" :close="closeAlert"></v-alert-success>
    <!-- Form -->
    <v-row>
      <v-form ref="form" class="container" lazy-validation>
        <v-row>
          <v-col>
            <small>Target</small>
            <v-radio-group v-model="notificationObject.target">
              <v-radio key="All" value="0" label="All"></v-radio>
              <v-radio key="Customized" value="1" label="Customized Audience"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col class="text-right">
            <v-btn class="ma-2" large color="primary" @click="save">Save</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="showTarget">
          <v-col cols="8" style="margin-left: 3%">
            <v-card style="padding-left: 20px; padding-right: 20px">
              <v-row>
                <v-col cols="6">
                  <v-select
                      v-model="notificationObject.gender"
                      :items="genderDropdown"
                      item-text="key"
                      item-value="value"
                      label="Gender"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row style="padding-left: 10px">
                <small>Age</small>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <v-text-field
                      type="number"
                      min="0"
                      v-model="notificationObject.ageFrom"
                      :rules="ageFromRules"
                  ></v-text-field>
                  </v-col>
                <v-col cols="1" style="margin-top: 18px">
                  to
                </v-col>
                <v-col cols="2">
                  <v-text-field
                      type="number"
                      min="0"
                      v-model="notificationObject.ageTo"
                      :rules="ageToRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" style="margin-top: 18px">
                  years old
                </v-col>
              </v-row>
              <v-row>
              <v-col cols="6">
                <v-select
                    :items="provinces"
                    v-model="notificationObject.province"
                    label="Province"
                ></v-select>
              </v-col>
            </v-row>
              <v-row style="padding-left: 10px">
                <small>Register date</small>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <custom-date-picker v-model="notificationObject.registerDateFrom" label="From" ></custom-date-picker>
                </v-col>
                <v-col cols="6">
                  <custom-date-picker v-model="notificationObject.registerDateTo" label="To" :rule="dateToRule"></custom-date-picker>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <small>Publish Date</small>
            <v-radio-group v-model="notificationObject.publishDate">
              <v-radio key="Now" value="0" label="Now"></v-radio>
              <v-radio key="Inactive" value="1" label="Set date and time"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row v-if="showPublishDate">
          <v-col cols="8" style="margin-left: 3%">
            <v-card style="padding-left: 20px; padding-right: 20px">
              <v-row>
                <v-col cols="6">
                  <custom-date-picker v-model="notificationObject.date" label="Date" :rule="notifyDateRule"></custom-date-picker>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                      v-model="notificationObject.time"
                      type="time"
                      :rules="notifyTimeRule"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="4">
            <v-multiple-selected
                title="Branch *"
                :branch="branchList"
                :selectedBranch="notificationObject.orgs ? notificationObject.orgs : []"
                :syncData="syncSelectedData"
                :isEditMode="isEditMode"
                v-if="!isLoading"
            ></v-multiple-selected>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-img
                v-show="notificationObject.image"
                :src="notificationObject.image"
                aspect-ratio="4"
                contain
            ></v-img>
            <v-file-input
                ref="inputRef"
                :rules="ImageRules"
                :error="isImageError"
                :error-messages="imageErrorMessage"
                prepend-icon
                append-icon="mdi-camera"
                label="Image"
                accept="image/png, image/jpeg, image/bmp"
                @change="updateImage"
                @click:clear="updateImage"
            ></v-file-input>
          </v-col>
        </v-row>

        <v-row>
          <!-- Thai -->
          <v-col>
            <v-card class="mx-auto">
              <v-card-title>THAI</v-card-title>

              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                        label="Title"
                        required
                        v-model="notificationObject.contents[0].title"
                        :rules="rules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                        label="Description"
                        required
                        v-model="notificationObject.contents[0].description"
                        :rules="rules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>

          <!-- English -->
          <v-col>
            <v-card class="mx-auto">
              <v-card-title>ENGLISH</v-card-title>

              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                        label="Title"
                        required
                        v-model="notificationObject.contents[1].title"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                        label="Description"
                        required
                        v-model="notificationObject.contents[1].description"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>

        <div v-if="notificationObject.orgs.length > 0">
          <v-row>
            <v-checkbox v-model="browserMode" :label="`Web browser url`" @change="resetContent"></v-checkbox>
          </v-row>
          <v-row v-show="browserMode">
            <div style="width:60%">
              <v-select
                  label="Select Web browser Type"
                  :items="sections"
                  v-model="notificationObject.section"
                  :rules="sectionRules"
              ></v-select>
              <v-text-field label="URL" v-model="notificationObject.browser_url" required :rules="urlRules"></v-text-field>
            </div>
          </v-row>
        </div>
      </v-form>
    </v-row>
    <!-- Links -->
    <div v-if="notificationObject.orgs.length > 0">
      <v-row v-show="!isBrowser">
        <v-col cols="6">
          <contentDialog v-if="hasLinkedContent" activatorText="+ Add Link to Content"
                         :resetContent="unlinkContent"></contentDialog>
          <v-btn large v-else @click="unlinkContent">Unlink Content</v-btn>
        </v-col>
      </v-row>

      <!-- Content -->
      <v-row class="mt-12">
        <v-col>
          <p class="headline">{{notificationObject.linkedContent ? notificationObject.linkedContent[0].title :''}}</p>
          <p v-html="notificationObject.linkedContent ? notificationObject.linkedContent[0].text : ''"></p>
        </v-col>
        <v-col>
          <p
              class="headline"
          >{{notificationObject.linkedContent ? notificationObject.linkedContent[1].title : ''}}</p>
          <p v-html="notificationObject.linkedContent ? notificationObject.linkedContent[1].text : ''"></p>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
  import {mapState, mapActions} from 'vuex'
  import moment from 'moment'
  import contentDialog from './contentDialog'
  import {isUrl, convertSelectedData, convertFileToBase64} from '../../helper/helper'
  import PopupLoading from '../../components/PopupLoading'
  import AlertError from '../../components/AlertError'
  import AlertSuccess from '../../components/AlertSuccess'
  import MultipleSelected from '../../components/MultipleSelected.vue'
  import CustomDatePicker from '../../components/DatePicker'

  export default {
    components: {
      'custom-date-picker': CustomDatePicker,
      'v-popup-loading': PopupLoading,
      'v-alert-error': AlertError,
      'v-alert-success': AlertSuccess,
      'v-multiple-selected': MultipleSelected,
      contentDialog,
    },

    computed: {
      showTarget() {
        return this.notificationObject.target === '1'
      },
      showPublishDate() {
        return this.notificationObject.publishDate === '1'
      },
      browserMode: {
        get() {
          return this.isBrowser
        },
        set(value) {
          this.setBrowserMode(value)
          this.fetchSections()
          if (!value) {
            this.unlinkContent()
            this.resetBrowserMode()
          }
        },
      },
      branchList() {
        return this.orgObject
      },
      textMode() {
        return this.isEditMode ? 'Edit' : 'Create'
      },
      hasLinkedContent() {
        return !this.notificationObject.linkedContent
      },
      breadcrumbs() {
        return [
          {text: 'Dashboard', link: true, to: '/dashboard'},
          {text: this.textMode},
        ]
      },
      ...mapState('notification', [
        'notificationObject',
        'provinces',
        'isLoading',
        'sections',
        'isBrowser',
        'orgObject',
      ]),
    },

    methods: {
      closeAlert() {
        this.isError = false
        this.isSuccess = false
      },
      async save() {
        if (this.validate()) {
          const create = await this.create()
          if (create) {
            this.$refs.form.resetValidation()
            this.$refs.inputRef.clearableCallback()
            this.resetState()
            this.resetBrowserMode()
            this.fetchSections()
            return (this.isSuccess = true)
          }
        }
        return (this.isError = true)
      },
      resetContent() {
        if (this.isBrowser)
          this.unlinkContent()
      },
      unlinkContent() {
        this.setLinkedContent({
          section_id: null,
          content_id: null,
          contents: null,
        })
      },
      updateImage(file) {
        if (file && file.name) {
          if (this.isEditMode) {
            if (!String(file.type).includes('image')) {
              this.isImageError = true
              this.imageErrorMessage = 'Only support image file!'
              return
            } else if (!file || file.size > 1040000) {
              this.isImageError = true
              this.imageErrorMessage = 'Banner size should be less than 1 MB!'
              return
            } else {
              this.setImage(file)
              this.isImageError = false
              this.imageErrorMessage = ''
              return
            }
          } else {
            this.setImage(file)
          }
        } else {
          this.notificationObject.image = ''
        }
      },
      validate() {
        if (this.$refs.form.validate() && !this.isImageError) {
          return true
        } else {
          this.isError = true
          return false
        }
      },
      imageRule(val) {
        if (this.isEditMode) {
          if (isUrl(val)) {
            return true
          } else {
            if (val !== '') {
              return true
            }
            return 'Notification image is required'
          }
        } else {
          if (!val || val.size > 500000) {
            return 'Notification image size should be less than 500 kb!'
          }
          if (!val || !String(val.size).includes('image')) {
            return 'Only support image file!'
          } else return true
        }
      },
      syncSelectedData(data) {
        this.notificationObject.orgs = convertSelectedData(
          data,
          this.orgObject,
          this.notificationObject.orgs,
        )
        if (this.notificationObject.orgs.length === 0) {
          this.setBrowserMode(false)
          this.unlinkContent()
        }
      },
      ...mapActions('notification', [
        'resetState',
        'setLinkedContent',
        'setImage',
        'setEditMode',
        'create',
        'fetchSections',
        'setBrowserMode',
        'resetBrowserMode',
        'setOrgInfo',
        'fetchContentList',
        'fetchProvinceList',
      ]),
    },

    async created() {
      // this.isEditMode = !!this.$route.params.id
      // this.setEditMode(this.isEditMode)
      // if (this.isEditMode) {
      //   await this.setBannerId(this.$route.params.id)
      //   this.fetchSections()
      // } else {
      //   this.resetState()
      // }
      this.resetState()
      this.resetBrowserMode()
      await this.setOrgInfo()
      await this.fetchProvinceList()

    },
    data() {
      return {
        isError: false,
        isSuccess: false,
        isEditMode: false,
        isImageError: false,
        imageErrorMessage: '',
        table: {
          headers: [
            {text: 'Title [TH]', value: 'title_th'},
            {text: 'Title [EN]', value: 'title_en'},
            {text: 'Status', value: 'status'},
          ],
        },
        genderDropdown: [{key: 'All', value: ''}, {key: 'Male', value: 'M'}, {key: 'Female', value: 'F'}],
        editorOption: {
          modules: {
            imageUploader: {
              upload: file => {
                return new Promise(async resolve => {
                  let base64 = await convertFileToBase64(file)
                  let payload = {base64: base64, name: file.name}
                  let url = await this.upload(payload)
                  resolve(url)
                })
              }
            },
            toolbar: [
              [{header: [1, 2, 3, 4, 5, 6, false]}],
              ['bold', 'italic', 'underline', 'strike'],
              [{list: 'ordered'}, {list: 'bullet'}],
              ['link', 'image']
            ]
          }
        },
        rules: [v => !!v || 'This field is required'],
        dateToRule: [v => !!v || 'Date is required', v => v >= this.notificationObject.registerDateFrom || 'Date must be greater than date from'],
        notifyDateRule: [v => !!v || 'Date is required', v => v >= moment().format('YYYY-MM-DD') || 'Date must be greater than now'],
        notifyTimeRule: [v => !!v || 'Time is required', v => !(this.notificationObject.date === moment().format('YYYY-MM-DD') && v <= moment().format('HH:mm')) || 'Time must be greater than now'],
        ageFromRules: [v => !!v && v >= 0 && v <= 999 || 'Age should be between 0-999'],
        ageToRules: [v => !!v && v >= 0 && v <= 999 && v >= this.notificationObject.ageFrom || 'Age should be between 0-999 and greater than or equal from age'],
        TitleRules: [v => !!v || 'Title is required'],
        ImageRules: [
          value => !value || value.size < 500000 || 'Notification image size should be less than 500 KB!',
          value => !value || String(value.type).includes('image') || 'Only support image file!',
        ],
        sectionRules: [
          v => {
            if (!this.isBrowser) return true
            if (v) return true
            return 'Web browser type is required'
          },
        ],
        urlRules: [
          v => {
            if (!this.isBrowser) return true
            if (v === '') return 'Url is required'
            if (!isUrl(v)) return 'Url is invalid format'
            return true
          },
        ],
      }
    },
  }
</script>

<style scoped>
  .form .col {
    padding-top: 0;
    padding-bottom: 0;
  }
</style>
